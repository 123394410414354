import React, { useState, useEffect } from "react"
//import elsaMainImg from "../img/elsaMain.png"
import elsa01 from "../img/elsa_0.png"
//import ElsaPic1 from "../img/elsa_gateway_r.png"
import ElsaPic1 from "../components/elsa/elsaPic1"
import ElsaPic2 from "../components/elsa/elsaPic2"
import ElsaPic3 from "../components/elsa/elsaPic3"
import CoverflowEffect from "../components/elsa/CoverflowEffect"
import WhereTo from "../components/elsa/whereTo"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
//import elsaPc from "../img/elsa_pc.png"
//import elsaMobile from "../img/elsa_mobile.png"
import achieveIcon_0 from "../img/icons/achieveIcon_0.png"
import achieveIcon_1 from "../img/icons/achieveIcon_1.png"
import achieveIcon_2 from "../img/icons/achieveIcon_2.png"
import achieveIcon_3 from "../img/icons/achieveIcon_3.png"

import classNames from "classnames"

function useScroll(position) {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handler = () => {
      setScrolled(window.scrollY > position)
    }
    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [position])

  return scrolled
}

function Elsa() {
  const scrolled = useScroll(1700)
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div
        className={classNames(
          "elsaWrap backColor",
          scrolled && "backColorAnimated"
        )}
      >
        <div className="sectionWrap elsaOverview">
          <p className="elsaMainImg">{/*<img src={elsaMainImg} alt="" />*/}</p>
          <div className="textWrap">
            <h2>
              ELSA
              <span>Elevator Smart Advisor</span>
            </h2>
            <div className="bodyText">
              <p>로컬 현장과 관리센터를 연결하는 지능형 IoT</p>
              <strong>
                ELSA는 Elevator Smart Advisor의 약자 임과 동시에 Everything's
                Linked, Smart and Safe to All 를 의미합니다.
              </strong>
              <br />
              엘리베이터승강기, 에스컬레이터, 스마트물류, 주차타워 등 산업
              전반에 적용이 가능한 (주)엠투엠테크의 엘사(ELSA)솔루션은
              로컬현장과 클라우드 센터를 연결하는 지능형 IoT Gateway를 통하여 각
              노드 간 통신, 보안 등 최신 국제 IoT표준, 웹 보안 표준을 적용한
              고품질의 안전한 사물인터넷 서비스 환경을 제공합니다. 또한,
              클라우드 시스템 이용으로 탄력적 서버운용 및 사용하는 만큼
              과금하므로 대규모 현장은 물론 소규모 현장에서도 저렴한 가격에
              서버를 구축하여 엘사솔루션을 이용할 수 있습니다.
            </div>
          </div>
        </div>
        <div className="sectionWrap elsaDetail">
          <div className="textWrap">
            <h2>스마트 원격케어 예지보전 시스템</h2>
            <p className="bodyText">
              엘사(ELSA) 솔루션은 엘리베이터 주요 핵심 안전부품 및 센서로부터
              실시간으로 데이터를 취합, 분석하여 사용자에게 사고예방과 고장
              예측이 가능한 지능화된 서비스를 제공합니다. IoT기반의 스마트
              원격케어 시스템, IoT디바이스, 서버, 노드간 통신, 웹 보안 등 최신
              국제보안표준을 적용하여 안전한 사물인터넷 서비스 환경을
              제공합니다.
            </p>
          </div>
          <div className="elsaBenefit">
            <ul>
              <li>
                <p className="kind">기록 및 관리</p>
                <p className="kindDetail">
                  손쉬운 기기, 현장 추가 삭제, 기기 운행 로그, 고장 및 에러
                  이력을 자동 기록하고 조회, 필터링, 검색 할 수 있습니다.{" "}
                </p>
              </li>
              <li>
                <p className="kind">신속하고 정확한 고장감지 및 대응</p>
                <p className="kindDetail">
                  고장과 출동상황 발생 시 즉시 중앙상황실, 각 센터, 엔지니어에게
                  PC 와 모바일기기에 알림을 보냅니다. 이동중에도 즉각적인 대응이
                  가능합니다.
                </p>
              </li>
              <li>
                <p className="kind">인공지능 AI</p>
                <p className="kindDetail">
                  영상, 진동, 음향 정보를 수집 후 딥러닝을 통해 비상상황을
                  예측하고 상황시 처리 메뉴얼 또는 필요/가용 부품을 추천합니다.
                </p>
              </li>
              <li>
                <p className="kind">모니터링</p>
                <p className="kindDetail">
                  현장, 승강기등 기기의 노후화를 예측 관리. 부품의 재고상황을
                  수시로 체크하지 않아도 관리가 가능하도록 알려줍니다.
                </p>
              </li>
              <li>
                <p className="kind">원격 제어/진단</p>
                <p className="kindDetail">
                  원격으로 기기를 제어하여 시험운행, 데일리 시스템 점검을
                  예약운행 할 수 있으며, 고장발생시 출동하지 않아도 상황실에서
                  조작 및 해결이 가능합니다.
                </p>
              </li>
              <li>
                <p className="kind">통계와 보고서 제공</p>
                <p className="kindDetail">
                  기기의 운행과 업무결과에 대한 통계를 표와 그래프로 알기 쉽게
                  제공하며 보고서를 자동생성하고 자유롭게 활용할 수 있습니다.
                </p>
              </li>
              <li>
                <p className="kind">합리적인 비용으로 지출 절감</p>
                <p className="kindDetail">
                  현장의 규모에 따라 서비스를 선택하고 사용하는 만큼의 과금을
                  통해 높은 비용절감 효과를 누릴 수 있습니다.
                </p>
              </li>
              <li>
                <p className="kind">엔지니어 관리</p>
                <p className="kindDetail">
                  엔지니어의 가용 인원을 관리하고 위치와 상황이 실시간으로
                  보고되며 업무 또는 상황 처리후 리포팅이 자동화되어 효율적이고
                  빠른 업무가 가능합니다.
                </p>
              </li>
            </ul>
          </div>
          <div
            className={classNames("elsa01Img", scrolled && "elsa01ImgAnimated")}
          >
            <img src={elsa01} alt="" />
          </div>
          <div className="elsaAchieve">
            <div className="textWrap">
              <h2>기술의 혁신과 독창성 인증</h2>
              <p className="bodyText">
                IoT기반의 스마트 원격케어 시스템, IoT디바이스, 서버, 노드간
                통신, 웹 보안 등 국내를 넘어 국제 기준을 준수, 최신
                국제보안표준을 적용하고 독창적인 기술개발을 통해 엘사서비스만의
                안전하고 빠른 사물인터넷 서비스환경을 제공합니다.
              </p>
            </div>
            <ul>
              <li>
                <p className="imgWrap">
                  <img src={achieveIcon_0} alt="" />
                </p>
                <p>
                  국내최초 CoAP 상호 운용성
                  <br /> TTA Verified 인증 획득
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={achieveIcon_1} alt="" />
                </p>
                <p>
                  엘리베이터 사물인터넷 기반 부지중
                  <br /> 자율안내 시스템 및 방법/구현 특허 2019
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={achieveIcon_2} alt="" />
                </p>
                <p>
                  세계 최고수준의 웹 보안성 평가
                  <br /> Grade A+ (상위 0.19%) 획득
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={achieveIcon_3} alt="" />
                </p>
                <p>
                  초당 전송처리량 4.5배 성능의
                  <br /> CoAP IoT Stack 을 적용
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="sectionWrap elsaServices">
          <div className="backgroundAnimation elsaBackgroundAnimation">
            <p></p>
          </div>
          <div className="textWrapMain">
            <h2>ELSA Service</h2>
          </div>
          <ul>
            <li className="elsaServices0">
              <div className="elsaServicesImg elsaServicesImg0">
                <ElsaPic2 />
              </div>
              <div className="textWrap">
                <h2>
                  ELSA IoT Gateway<span className="tmText">TM</span>
                </h2>
                <p className="serviceKind">IT and OT Data</p>
                <p className="bodyText">
                  <span>데이터 수집 장비</span>
                  ELSA IoT Gateway<span className="tmText">TM</span>은 현장의
                  각종 설비의 핵심 장치와 센서(Things) 및 모바일 기기를 클라우드
                  센터와 연결하는 제조사/벤더 독립적인 지능형 IoT 장치입니다.
                </p>
                <ol>
                  <li>
                    <span>연결성</span>
                    <span>– IoT Stack에 의한 데이터 소스에 쉽게 연결</span>
                    <span>– IoT Stack에 의한 데이터 소스에 쉽게 연결</span>
                  </li>
                  <li>
                    <span>보안성 / 안정성</span>
                    <span>– 데이터 암호화</span>
                    <span>– 보안전문 인력 보유</span>
                  </li>
                  <li>
                    <span>확장성</span>
                    <span>– Legacy연결을 위한 Bridge / Ext. 기능</span>
                    <span>– 오픈 플랫폼 기반</span>
                    <span>– 국제 IoT 표준규격 준수</span>
                  </li>
                </ol>
              </div>
            </li>
            <li className="elsaServices1">
              <div className="elsaServicesImg elsaServicesImg1">
                <ElsaPic1 />
              </div>
              <div className="textWrap">
                <h2>ELSA.Edge</h2>
                <p className="serviceKind">Secure edge-to-cloud processing</p>
                <p className="bodyText">
                  <span>
                    신속하고 합리적인 빅데이터 분석 처리, 클라우드서비스
                  </span>
                  ELSA.Edge 는 엣지 컴퓨팅을 이용하여 빅데이터를 위변조 없이
                  강력한 보안하에서 수신받아 실시간으로 처리분석하는 기술입니다.
                  언제 어디서나, 어떤 모바일 기기에서도 서비스를 이용할 수 있게,
                  빠른속도로 데이터를 전송하도록 설계된 통합 · 개방형 IoT
                  플랫폼입니다.
                </p>
                <ol>
                  <li>
                    <span>IoT/AI 프레임워크</span>
                    <span>– 데이터 수집, 분석 및 AI학습</span>
                    <span>
                      – 프로토콜 어댑터, 데이터 버스, 최신 Web/App서비스
                      컨테이너기반 프레임워크
                    </span>
                    <span>– Node 인증, 권한관리, SW배포 기능</span>
                  </li>
                  <li>
                    <span>유연성</span>
                    <span>– 사용량에 따른 과금</span>
                    <span>– 단시간에 서버 확장 가능</span>
                  </li>
                  <li>
                    <span>편리성</span>
                    <span>– 언제, 어디서든 인터넷을 통한 접근</span>
                    <span>– Secure 브라우즈 기반 데이터 접근</span>
                    <span>– PaaS 플랫폼에 의한 Private운영센터</span>
                  </li>
                </ol>
              </div>
            </li>
            <li className="elsaServices2">
              <div className="elsaServicesImg elsaServicesImg2">
                <ElsaPic3 />
              </div>
              <div className="textWrap">
                <h2>
                  ELSA.Cloud SaaS<span className="tmText">TM</span>
                </h2>
                <p className="serviceKind">Industrial App</p>
                <p className="bodyText">
                  <span>
                    장비 상태체크 및 워크플로우 모니터링, 통계보고 + ERP 플랫폼
                  </span>
                  ELSA.Cloud SaaS<span className="tmText">TM</span> 는 유무선
                  인터넷이 가능한 어떤 기기에서든 접속하는 반응형 웹/앱
                  서비스로서 실시간 상태체크 및 원격대응 워크플로우 모니터링,
                  인력관리, 통계보고가 가능, 일목요연한 UX/UI를 제공합니다.
                  <br />
                  <br />
                  탄력적인 서버운용 및 과금하는 구조로 대규모 현장은 물론 소규모
                  현장에서도 최적의 가격으로 서비스를 이용할 수 있습니다.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="sectionWrap where">
          <div className="textWrap">
            <h2>적용 가능 분야</h2>
            <p className="bodyText">
              대부분의 산업 전반에서 사용가능한 솔루션으로 기계와 사람이
              소통하는 통로로서 역할하고 기업운영에 필수적인 업무 효율, 비용
              감소등의 효과를 제공합니다.
            </p>
          </div>
          <WhereTo />
        </div>
        <div className="sectionWrap elsaCloud">
          <div className="textWrap">
            <h2>사용자에 최적화된 ELSA.Cloud 플랫폼</h2>
            <p className="bodyText">
              데스크탑, 패드 모바일 등 다양한 디바이스에서 일목요연한 통계분석,
              오류 콜 접수 관리 화면을 제공합니다. 손쉽게 현장과 승강기를 추가 /
              관리할수 있고 각종 커스터마이즈 설정으로 엔지니어에게는 더욱 더
              편한 근무환경을 제공하고 기업은 비용과 시간을 절감 할 수 있습니다.
            </p>
          </div>
          <div className="elsaCloudGallery">
            <CoverflowEffect />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Elsa
